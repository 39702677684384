
 const swear = [
    "fuck"
  ]

export const bogusCheck = (text) =>{
    const foundSwears = swear.filter(word => text.toLowerCase().includes(word.toLowerCase()));
    if(foundSwears.length){
       return true
    } else {
       return false
    }
}