import { Delete, Search } from "@mui/icons-material";
import { CircularProgress, InputBase } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";

const Comments = () => {
  const [phrase, setPhrase] = useState("");
  const [comments,setComments] = useState([])
  const [loader, setLoader] = useState(false)

  const discardComment = async(comment_id) => {
    setLoader(true);
    axios.get("https://hacks50-backend.herokuapp.com/api/comment/discard/"+comment_id)
    
    .then((res)=>{
      console.log(res)
      setLoader(false);
    })
    .catch((err)=>{
      console.log(err)
      setLoader(false); 
    })
  }
  const search = () => {
    setLoader(true)
    axios
      .post(
        "https://hacks50-backend.herokuapp.com/api/comment/comments-by-search",
        {
          search: phrase,
        }
      )
      .then((res) => {
        console.log(res)
        setComments(res.data)
    setLoader(false)
    })
      .catch((err) => {
        console.log(err);
        setLoader(false)
      });
  };
  return (
    <>
      <InputBase
        sx={{
          bgcolor: "white",
          width: "100%",
          pl: 4,
          py: 1,
          fontSize: "18px",
        }}
        onChange={(e) => {
          setPhrase(e.target.value);
        }}
        endAdornment={<Search onClick={search} />}
      />



      {loader ? ( 
      <div className="flex justify-center h-[70vw] lg:h-fit  mt-[20%] ">
          <div>
            <div className="flex justify-center">
              {" "}
              <CircularProgress thickness={1} sx={{ color: "#e74c3c" }} />{" "}
            </div>
            <div className="lg:text-2xl text-xl font-bold tracking-tight text-center lg:px-3 text-gray-700">
              {" "}
              loading  ...{" "}
            </div>
          </div>
        </div>) : (
        <> 
        {comments && comments.length > 0 ? comments.map((item, index )=>(
              <div key={index} className='mt-3 pl-4' >
               
              <div className="text-[18px] lg:text-[22px]  ">
                
                  <div className="flex items-start">
                   {index+1}.
                    <div className="flex-1">{item.comment} </div>
                    {item.img && (
                      <img
                        src={item.img}
                        onClick={() => window.open(item.img, "_blank")}
                        className="w-[50px] h-[40px] rounded-md "
                      />
                    )}
                  </div>{" "}
                </div>
        
                <div className=" text-[16px] text-[#9197a0]  ">
                  <span>
                    By
                    <span className=" hover:text-[#e74c3c] cursor-pointer ">
                      {
                        item.username
                      }
                    </span>
                    <span> {moment(item.createdAt).fromNow()}</span>
                   
                    <span
                      className="text-black hover:text-[#e74c3c]  cursor-pointer px-[3px] py-[2px] rounded-[3px] "
                    >
                       <Delete sx={{ fontSize:{lg:'30px', xs:'27px'} }}  onClick={()=> discardComment(item._id)} />
                    </span>
                  </span>
                </div>
              </div>

        )) 
        
        :  (
            'no comments for phrase'
        )}
        </>
      )}
    </>
  );
};

export default Comments;
