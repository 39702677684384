import { Add } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import React, { useState } from "react";
import NewHackModal from "./NewHackModal";

const Header = () => {
    const [open, setOpen] = useState(false)
  return (
    <div className="bg-white shadow-lg shadow-gray-400 rounded-[20px] py-3 px-2  flex justify-between
     items-center w-full lg:w-full ">
        <NewHackModal open={open} setOpen={setOpen}/>
      <div
        className="bg-[#e74c3c] hover:bg-[#b42c1c]   w-fit flex items-center text-white lg:py-[20px] py-[8px]
          px-[4px] lg:px-[8px] cursor-pointer rounded-[8px] "

          onClick={()=>{
            setOpen(true)
          }}
      >
        <div>
          {" "}
          <Add sx={{ color: "white" }} />{" "}
        </div>
        <div> New Hack </div>
      </div>


      <div className="flex items-center lg:text-[22px] hover:bg-gray-300 hover:lg:text-[20px]  
       hover:text-[18px]
      hover:py-2 hover:px-2 rounded-[8px] cursor-pointer " onClick={()=> window.location.pathname = '/'} >
        <div className='relative bottom-2 -rotate-12 ' >🍼</div>
        <div className=' lg:text-[24px] font-[800]  ' > BabyHack </div>
        <div className='relative top-2 rotate-12 ' >🧑🏾</div>
      </div>


      <div className="flex items-center space-x-1 " > 
        <div>
        {/* <Avatar>
        👨🏻‍🦰
        </Avatar> */}
        </div>

        <div className="space-y-[-6px]" >
            <div>By</div>
            <div className="underline text-[#e74c3c] cursor-pointer "
            onClick={()=> localStorage.clear()}
            >Parents</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
