import { atom } from "recoil";

export const hackState = atom({
    key:'hack',
    default: []
})

export const load1state = atom({
    key:'load1',
    default: true
})

export const user_idstate = atom({
    key:'user_id',
    default: localStorage.getItem('user_id') || null
})

export const user_namestate = atom({
    key:'user_name',
    default: localStorage.getItem('user_name') || null
})