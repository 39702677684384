import logo from "./logo.svg";
import "./App.css";
import Main from "./Main";
import { RecoilRoot } from "recoil";



function App() {
  return (
    <RecoilRoot>
        <Main />
    </RecoilRoot>
  );
}

export default App;
