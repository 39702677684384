import { Twitter } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import HackDetailModal from "./HackDetailModal";
import moment from "moment";
import axios from "axios";
import { useRecoilState } from "recoil";
import { user_idstate } from "../../state";
import AuthModal from "./AuthModal";
import { useParams } from "react-router-dom";

const Hack = ({ index, hack }) => {
  const [open, setOpen] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [user_id, setUserId] = useRecoilState(user_idstate)
  const [likes, setLikes] = useState(hack.likes)

  const react = (reaction) =>{

    axios.post("https://hacks50-backend.herokuapp.com/api/hacks/react", {
      sub_id : hack._id,
      user_id,
      reaction

    })

    .then((res)=>{
      console.log(res)
      setLikes(res.data.hack.likes)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  // useEffect(() => {
  //   localStorage.clear()
  // }, [])


  
  const {hack_id} = useParams()

  

  useEffect(()=>{
    if(hack_id){
     try {
      document.getElementById(hack_id).click()
     } catch (error) {
      
     }
    }
  },[hack])
  
  return (
    <>
    <AuthModal openAuthModal={openAuthModal} setOpenAuthModal={setOpenAuthModal}  />
      <HackDetailModal open={open} setOpen={setOpen} hack={hack} />
      <div
        className="w-full flex items-start bg-white py-3 px-3 rounded-[10px] shadow-md shadow-gray-400 
  space-x-2  hover:opacity-80 cursor-pointer
  "
       
      >
        <div></div>
        <div className="space-y-4 flex-1"  >
          <div className="flex items-start">
            <div className="flex-1" onClick={() => setOpen(true)} id={hack._id} >{hack.content}</div>
            {hack.file_type && hack.file_type.substr(0,5) === 'image' && <img src={hack.file} onClick={()=> window.open(hack.file, '_blank')} className='w-[170px] h-[120px]' />}
            {hack.file_type && hack.file_type.substr(0,5) === 'video' && <video src={hack.file+'#t=0.001'} onClick={()=> window.open(hack.file, '_blank')} className='w-[170px] h-[120px]' />}
           
          </div>

          <div className=" text-[12px] text-[#9197a0]  ">
            <span>
              By
              <span className=" hover:text-[#e74c3c] cursor-pointer "
               onClick={() => setOpen(true)}
              >
                {" "}
                {hack.twitter_handle ? (
                  <>
                    <Twitter sx={{ fontSize: "16px" }} />
                    {hack.twitter_handle}
                  </>
                ) : (
                  hack.authorname
                )}{" "}
              </span>
              {moment(hack.createdAt).fromNow()} |
              <span className=" hover:text-[#e74c3c] cursor-pointer "
              onClick={() => setOpen(true)}
              >
                {" "}
                {hack.CommentsCount} comment{" "}
              </span>
              |{" "}
              <span className=" hover:text-[#e74c3c] cursor-pointer "
              onClick={()=>window.location.href = "mailto:mail@example.org"}
              >
                {" "}
                report{" "}
              </span>{" "}
              |
              <span className=" hover:text-[#e74c3c] cursor-pointer " onClick={() => setOpen(true)}>
                {" "}
                share{" "}
              </span>
            </span>
          </div>
        </div>

        <div>
          <div className="cursor-pointer"
          onClick={()=>{
            if(user_id){
              react(1)
            }else{
              setOpenAuthModal(true)
            }
          }}
          > 😇</div>
          <div className="text-center font-bold "> {likes}</div>
          <div className="cursor-pointer"
          onClick={()=>{
            if(user_id){
              react(-1)
            }else{
              setOpenAuthModal(true)
            }
          }}
          >🤔</div>
        </div>
      </div>
    </>
  );
};

export default Hack;
