import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  TwitterIcon,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  WorkplaceShareButton,
  TelegramIcon,
  LinkedinIcon,
  TumblrIcon,
  RedditIcon,
  PinterestIcon,
} from "react-share";

const Share = ({hack}) => {
  return (
    <div className="space-x-2">
      <FacebookShareButton
        url={`50hacks.vercel.app/hack/${hack._id}`}
        quote={"😎/🤔 - "+hack.content}
        hashtag="#😎/🤔 -hack"
      >
        <FacebookIcon size={36} className="rounded-full" />
      </FacebookShareButton>

      <TwitterShareButton
        url={`50hacks.vercel.app/hack/${hack._id}`}
        title={"😎/🤔 - "+hack.content}
        hashtag="#50-hacks"
      >
        <TwitterIcon size={36} className="rounded-full" />
      </TwitterShareButton>
      <WhatsappShareButton
        url={`50hacks.vercel.app/hack/${hack._id}`}
        title={"😎/🤔 - "+hack.content}
        separator=":: "
      >
        <WhatsappIcon size={36} className="rounded-full" />
      </WhatsappShareButton>

      <TelegramShareButton
        url={`50hacks.vercel.app/hack/${hack._id}`}
        title={"😎/🤔 - "+hack.content}
      >
        <TelegramIcon size={36} className="rounded-full" />
      </TelegramShareButton>

      <LinkedinShareButton
        url={`50hacks.vercel.app/hack/${hack._id}`}
        title={"😎/🤔 - "+hack.content}
        summary={"#😎/🤔 -"+hack.content}
        source='new'
      >
        <LinkedinIcon size={36} className="rounded-full" />
      </LinkedinShareButton>

      <TumblrShareButton
        url={`50hacks.vercel.app/hack/${hack._id}`}
        title={"😎/🤔 - "+hack.content}
        caption={"😎/🤔 - "+hack.content}
        tags=""
      >
        <TumblrIcon size={36} className="rounded-full" />
      </TumblrShareButton>

      <RedditShareButton
        url={`50hacks.vercel.app/hack/${hack._id}`}
        title={"😎/🤔 - "+hack.content}
      >
        <RedditIcon size={36} className="rounded-full" />
      </RedditShareButton>

      <PinterestShareButton
        media=""
        description={"😎/🤔 - "+hack.content}
      >
        <PinterestIcon size={36} className="rounded-full" />
      </PinterestShareButton>
    </div>
  );
};

export default Share;
