import { KeyboardArrowDown } from "@mui/icons-material";
import ReactSelect, { components } from "react-select";

const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      borderRadius:'12px',
      backgroundColor:'#fff',
      " @media (min-width: 1024px)": {
        fontSize: "14px",
        width:'100%',
        paddingTop:'5px',
        paddingBottom:'5px'
      },
      " @media (max-width: 1024px)": {
        fontSize: "13px",
      },
    }),
  };

  const style2 = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      borderRadius:'6px',
      backgroundColor:'#fafafa',
      " @media (min-width: 1024px)": {
        fontSize: "16px",
        width:'128px',
        paddingTop:'5px',
        paddingBottom:'5px'
      },
      " @media (max-width: 1024px)": {
        fontSize: "13px",
      },
    }),
  };
  
  const Placeholder = (props) => {
    return (
      <components.Placeholder {...props}>
        <div className="text-gray-400 text-[14px] ">{props.children}</div>
      </components.Placeholder>
    );
  };
  
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <KeyboardArrowDown sx={{ color: "black", fontSize: "20px" }} />
      </components.DropdownIndicator>
    );
  };

   export  const select ={
        style,Placeholder,DropdownIndicator, style2
  }