import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Welcome = ({count}) => {
    const navigate = useNavigate()
  return (
    <div className='flex justify-center ' >

       <div  className='space-y-3' >
       


        <div>
            <Button
            sx={{
                 bgcolor:'#e74c3c',
                 color:'white',
                 ':hover': {
                    bgcolor:'#e74c3c',
                    color:'white'
                 },
                 width: {lg:'400px', xs:'100%'},
                 borderRadius:'0px',
                 textTransform:'none',
                 py: 2,
                 fontSize:'18px'
            }}
            onClick={()=> navigate('/admin/hacks')}
            >


                Hacks

            </Button>
        </div>


        <div>
            <Button
            sx={{
                 bgcolor:'#e74c3c',
                 color:'white',
                 ':hover': {
                    bgcolor:'#e74c3c',
                    color:'white'
                 },
                 width: {lg:'400px', xs:'100%'},
                 borderRadius:'0px',
                 textTransform:'none',
                 py: 2,
                 fontSize:'18px'
            }}
            onClick={()=> navigate('/admin/comments')}
            >


                Comments

            </Button>
        </div>



        <div>
            <Button
            sx={{
                 bgcolor:'#e74c3c',
                 color:'white',
                 ':hover': {
                    bgcolor:'#e74c3c',
                    color:'white'
                 },
                 width: {lg:'400px', xs:'100%'},
                 borderRadius:'0px',
                 textTransform:'none',
                 py: 2,
                 fontSize:'18px'
            }}
            onClick={()=> navigate('/admin/reports')}
            >


                Reports

            </Button>
        </div>
       </div>

    </div>
  )
}

export default Welcome