import {
  AutoAwesome,
  SignalCellular0Bar,
  SignalCellularAlt,
  Twitter,
  Whatshot,
} from "@mui/icons-material";
import { Avatar, CircularProgress, Divider, Fade } from "@mui/material";
import React, { useEffect, useState } from "react";
import Hack from "../molecules/Hack";
import { Helmet } from "react-helmet";
import axios from "axios";

const HomeScreen = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isloading, setLoader] = useState(true);
  const [categoryId, setCategoryId] = useState(null);

  const [hacks, setHacks] = useState([]);
  useEffect(() => {
    const getHacks = async () => {
      setLoader(true);
      axios
        .get("https://hacks50-backend.herokuapp.com/api/hacks/get-verified-hacks")
        .then((res) => {
          setLoader(false);
          setHacks(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getHacksByCategory = async () => {
      setLoader(true);
      axios
        .get(
          "https://hacks50-backend.herokuapp.com/api/hacks/get-hacks-by-category/" +
            categoryId
        )
        .then((res) => {
          setLoader(false);
          setHacks(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (!categoryId) {
      getHacks();
    } else {
      getHacksByCategory();
    }
  }, [categoryId]);

  const [loader2, setLoader2] = useState(false);

  const sortHacks = async (sort) => {
    setLoader2(true);

    axios
      .get(
        `https://hacks50-backend.herokuapp.com/api/hacks/get-hacks-by-sort/${
          categoryId ? categoryId : "null"
        }/${sort}`
      )
      .then((res) => {
        setLoader2(false);
        setHacks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BabyHacks - The top community for parents to share quick hacks for their babies. </title>
        <link rel="canonical" href="https://mysite.com/example" />
      </Helmet>
      <div className="lg:text-4xl text-2xl font-bold tracking-tight text-center lg:px-3 text-gray-700  my-11 ">
      BabyHacks is a community to share tips/tricks for babies.

      <div className="lg:text-lg text-sm mt-4 italic font-semibold text-center ">Vote & Comment your favorite baby hacks.  Share hacks that may help someone else. </div>
      </div>

      {/* <div className="grid grid-cols-4">
        <div
          className={
            categoryId === null
              ? "bg-gray-900 text-white py-3 px-4 text-center border-[1px] lg:border-2 border-gray-300 col-span-3 "
              : "bg-[#d7695d] cursor-pointer text-white py-3 px-4 text-center border-[1px] lg:border-2 border-gray-300 col-span-3 "
          }
          onClick={() => {
            setCategoryId(null);
          }}
        >
          All
        </div>

        <div
          className={
            categoryId === "6329d92b666d3edc64828762"
              ? "bg-gray-900 text-white py-3 px-4 text-center border-[1px] lg:border-2 border-gray-300 "
              : "bg-[#d7695d] cursor-pointer text-white py-3 px-4 text-center border-[1px] lg:border-2 border-gray-300 "
          }
          onClick={() => {
            setCategoryId("6329d92b666d3edc64828762");
          }}
        >
          Eating
        </div>
        <div
          className={
            categoryId === "6329d932666d3edc64828764"
              ? "bg-gray-900 text-white py-3 px-4 text-center border-[1px] lg:border-2 border-gray-300 "
              : "bg-[#d7695d] cursor-pointer text-white py-3 px-4 text-center border-[1px] lg:border-2 border-gray-300 "
          }
          onClick={() => {
            setCategoryId("6329d932666d3edc64828764");
          }}
        >
          Sleeping
        </div>
        <div
          className={
            categoryId === "6329d937666d3edc64828766"
              ? "bg-gray-900 text-white py-3 px-4 text-center border-[1px] lg:border-2 border-gray-300 "
              : "bg-[#d7695d] cursor-pointer text-white py-3 px-4 text-center border-[1px] lg:border-2 border-gray-300 "
          }
          onClick={() => {
            setCategoryId("6329d937666d3edc64828766");
          }}
        >
          Playing
        </div>
        <div
          className={
            categoryId === "6329d37600fbf6272d6f92ec"
              ? "bg-gray-900 text-white py-3 px-4 text-center border-[1px] lg:border-2 border-gray-300 col-span-2 "
              : "bg-[#d7695d] cursor-pointer text-white py-3 px-4 text-center border-[1px] lg:border-2 border-gray-300 col-span-2 "
          }
          onClick={() => {
            setCategoryId("6329d37600fbf6272d6f92ec");
          }}
        >
          Others
        </div>
      </div> */}

      <div
        className="flex items-center mt-4 w-full overflow-y-auto scrollmessages   "
        id="container"
      >
        <div className="flex items-center mt-4 space-x-2">
          <div
            className={
              categoryId === null
                ? "flex items-center bg-[#e74c3c] text-white rounded-full px-3 py-2 space-x-1 border-[1px] border-[#e74c3c] w-[285px] "
                : "flex items-center bg-white rounded-full px-3 py-2 space-x-1 border-[1px] border-[#e74c3c] cursor-pointer  w-[285px]"
            }
            onClick={() => {
              setCategoryId(null);
            }}
          >
            <Avatar sx={{ bgcolor: "transparent", fontSize: "25px" }}>
              😊
            </Avatar>

            <div className="text-[14px] lg:text-[16px] space-y-[-4px] ">
              <div className="font-semibold   "> All</div>
              <div className="text-[11px] lg:text-[13px] font-[400] ">
                See All Hacks from every category
              </div>
            </div>
          </div>

          <div
            className={
              categoryId === "6329d92b666d3edc64828762"
                ? "flex items-center bg-[#e74c3c] text-white rounded-full px-3 py-2 space-x-1 border-[1px] border-[#e74c3c] w-[285px] "
                : "flex items-center bg-white rounded-full px-3 py-2 space-x-1 border-[1px] border-[#e74c3c] cursor-pointer  w-[285px]"
            }
            onClick={() => {
              setCategoryId("6329d92b666d3edc64828762");
            }}
          >
            <Avatar sx={{ bgcolor: "transparent", fontSize: "25px" }}>
              🥣
            </Avatar>

            <div className="text-[14px] lg:text-[16px] space-y-[-4px] ">
              <div className="font-semibold  "> Eating</div>
              <div className="text-[11px] lg:text-[13px] font-[400] ">
                Eating Hacks
              </div>
            </div>
          </div>

          <div
            className={
              categoryId === "63321dda3e05085df585aad2"
                ? "flex items-center bg-[#e74c3c] text-white rounded-full px-3 py-2 space-x-1 border-[1px] border-[#e74c3c] w-[285px] "
                : "flex items-center bg-white rounded-full px-3 py-2 space-x-1 border-[1px] border-[#e74c3c] cursor-pointer  w-[285px]"
            }
            onClick={() => {
              setCategoryId("63321dda3e05085df585aad2");
            }}
          >
            <Avatar sx={{ bgcolor: "transparent", fontSize: "25px" }}>
            📚
            </Avatar>

            <div className="text-[14px] lg:text-[16px] space-y-[-4px] ">
              <div className="font-semibold  "> Learning</div>
              <div className="text-[11px] lg:text-[13px] font-[400] ">
                Learning tips & tricks
              </div>
            </div>
          </div>

          <div
            className={
              categoryId === "6329d932666d3edc64828764"
                ? "flex items-center bg-[#e74c3c] text-white rounded-full px-3 py-2 space-x-1 border-[1px] border-[#e74c3c] w-[285px] "
                : "flex items-center bg-white rounded-full px-3 py-2 space-x-1 border-[1px] border-[#e74c3c] cursor-pointer  w-[285px]"
            }
            onClick={() => {
              setCategoryId("6329d932666d3edc64828764");
            }}
          >
            <Avatar sx={{ bgcolor: "transparent", fontSize: "25px" }}>
              😴
            </Avatar>

            <div className="text-[14px] lg:text-[16px] space-y-[-4px] ">
              <div className="font-semibold "> Sleeping</div>
              <div className="text-[11px] lg:text-[13px] font-[400] ">
                Sleeping Hacks
              </div>
            </div>
          </div>

          <div
            className={
              categoryId === "6329d937666d3edc64828766"
                ? "flex items-center bg-[#e74c3c] text-white rounded-full px-3 py-2 space-x-1 border-[1px] border-[#e74c3c] w-[285px] "
                : "flex items-center bg-white rounded-full px-3 py-2 space-x-1 border-[1px] border-[#e74c3c] cursor-pointer  w-[285px]"
            }
            onClick={() => {
              setCategoryId("6329d937666d3edc64828766");
            }}
          >
            <Avatar sx={{ bgcolor: "transparent", fontSize: "25px" }}>
              🤾‍♀️
            </Avatar>

            <div className="text-[14px] lg:text-[16px] space-y-[-4px] ">
              <div className="font-semibold "> Playing</div>
              <div className="text-[11px] lg:text-[13px] font-[400] ">
                Playing hacks
              </div>
            </div>
          </div>

          <div
            className={
              categoryId === "6329d37600fbf6272d6f92ec"
                ? "flex items-center bg-[#e74c3c] text-white rounded-full px-3 py-2 space-x-1 border-[1px] border-[#e74c3c] w-[285px] "
                : "flex items-center bg-white rounded-full px-3 py-2 space-x-1 border-[1px] border-[#e74c3c] cursor-pointer  w-[285px]"
            }
            onClick={() => {
              setCategoryId("6329d37600fbf6272d6f92ec");
            }}
          >
            <Avatar sx={{ bgcolor: "transparent", fontSize: "25px" }}>
              📋
            </Avatar>

            <div className="text-[14px] lg:text-[16px] space-y-[-4px] ">
              <div className="font-semibold "> Others</div>
              <div className="text-[11px] lg:text-[13px] font-[400] ">
                Other Hacks
              </div>
            </div>
          </div>
        </div>
      </div>

      {isloading ? (
        <div className="flex justify-center h-[70vw] lg:h-fit  mt-[20%] ">
          <div>
            <div className="flex justify-center">
              {" "}
              <CircularProgress thickness={1} sx={{ color: "#e74c3c" }} />{" "}
            </div>
            <div className="lg:text-2xl text-xl font-bold tracking-tight text-center lg:px-3 text-gray-700">
              {" "}
              loading hacks ...{" "}
            </div>
          </div>
        </div>
      ) : (
        <>
          {hacks && hacks.length < 1 ? (
            <div>
              <div className="text-lg pt-3 font-semibold text-gray-900 text-center ">
                Oops! No Hacks 😫 .. comeback later for some
              </div>
            </div>
          ) : (
            <Fade in={true}>
              <div>
                <div className=" grid grid-cols-3 items-center  sticky top-0 bg-[#e6e6e6] z-50 pt-3   ">
                  {bars.map((item, index) => {
                    if (activeStep === index) {
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setActiveStep(index);
                            let sort = "hot";
                            switch (index) {
                              case 0:
                                sort = "hot";
                                break;

                              case 1:
                                sort = "new";
                                break;

                              case 2:
                                sort = "top";
                                break;

                              default:
                                break;
                            }
                            sortHacks(sort);
                          }}
                          key={index}
                        >
                          <div className="flex justify-center text-[24px] font-bold  text-[hsla(219,20%,25%,1)] ">
                            <span>
                              {" "}
                              <item.icon
                                sx={{ position: "relative", bottom: 2 }}
                              />{" "}
                            </span>
                            {item.name}
                          </div>
                          <div>
                            <Divider
                              sx={{
                                width: "100%",
                                borderBottom: "2px solid hsla(219,20%,25%,1) ",
                              }}
                            />
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setActiveStep(index);
                            let sort = "hot";
                            switch (index) {
                              case 0:
                                sort = "hot";
                                break;

                              case 1:
                                sort = "new";
                                break;

                              case 2:
                                sort = "top";
                                break;

                              default:
                                break;
                            }
                            sortHacks(sort);
                          }}
                          key={index}
                        >
                          <div className="flex justify-center text-[24px] font-bold  text-[#9197a0] ">
                            <span>
                              {" "}
                              <item.icon
                                sx={{ position: "relative", bottom: 2 }}
                              />{" "}
                            </span>
                            {item.name}
                          </div>
                          <div>
                            <Divider
                              sx={{
                                width: "100%",
                                borderBottom: "2px solid #9197a0  ",
                              }}
                            />
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>

                {loader2 ? (
                  <div className="flex justify-center  mt-[10%] ">
                    <div>
                      <div className="flex justify-center">
                        {" "}
                        <CircularProgress
                          thickness={1}
                          sx={{ color: "#e74c3c" }}
                        />{" "}
                      </div>
                      <div className="lg:text-2xl text-xl font-bold tracking-tight text-center lg:px-3 text-gray-700">
                        {" "}
                        sorting hacks ...{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-[30px] space-y-4 lg:space-y-3 ">
                    {hacks.map((item, index) => (
                      <div key={index}>
                        <Hack index={index} hack={item} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Fade>
          )}
        </>
      )}
    </div>
  );
};

export default HomeScreen;

const bars = [
  {
    name: "Hot",
    icon: Whatshot,
  },
  {
    name: "New",
    icon: AutoAwesome,
  },
  {
    name: "Top",
    icon: SignalCellularAlt,
  },
];
