import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { useRecoilState } from "recoil";
import { user_idstate } from "../../state";
import AdminHack from "../molecules/AdminHack";
import AuthModal from "../molecules/AuthModal";
import Comments from "./admin/Comments";
import Hacks from "./admin/Hacks";
import Reports from "./admin/Reports";
import Welcome from "./admin/Welcome";

const AdminScreen = () => {
  const [hacks, setHacks] = useState([]);
  const [loading, setLoader] = useState(true);
  const [user_id, setUserId] = useRecoilState(user_idstate);
  const [openAuthModal, setOpenAuthModal] = useState(false)

  useEffect(() => {
    const getHacks = async () => {
      setLoader(true);
      axios
        .get("https://hacks50-backend.herokuapp.com/api/hacks/")
        .then((res) => {
          setLoader(false);
          setHacks(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getHacks();
  }, [user_idstate]);

  return (
    <div className="mt-5">
        <AuthModal
        openAuthModal={openAuthModal}
        setOpenAuthModal={setOpenAuthModal}
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>🧳 Admin</title>
        <link rel="canonical" href="https://mysite.com/example" />
      </Helmet>
      <div className="lg:text-4xl text-2xl font-bold tracking-tight text-center lg:px-3 text-gray-700  my-11 ">
        Verify or discard hack/comments
      </div>
      {loading ? (
        <div className="flex justify-center h-[70vw] lg:h-fit  mt-[20%] ">
          <div>
            <div className="flex justify-center">
              {" "}
              <CircularProgress thickness={1} sx={{ color: "#e74c3c" }} />{" "}
            </div>
            <div className="lg:text-2xl text-xl font-bold tracking-tight text-center lg:px-3 text-gray-700">
              {" "}
              loading  ...{" "}
            </div>
          </div>
        </div>
      ) : (
        <div>
          { user_id &&  user_id === '633231dc67086cfa1ef27492' ? (
            <>
             <Routes>
              <Route path='/' element={<Welcome count={hacks.length} />}/>
              <Route path='/hacks' element={<Hacks loading={loading} setLoader={setLoader} hacks={hacks} setHacks={setHacks} />}/>
              <Route path='/comments' element={<Comments />}/>
              <Route path='/reports' element={<Reports />}/>
             </Routes>
            </>
          ) : (
           <div> 
             <div className="flex justify-center">
                <div
              className="bg-[#e74c3c] hover:bg-[#b42c1c]   w-fit flex items-center justify-center  text-white lg:py-[20px] py-[8px]
               lg:px-[8px] px-4 cursor-pointer rounded-[8px] "
              onClick={() => {
                  setOpenAuthModal(true)
              }}
            >
              <div> Please Login to an Admin account </div>
            </div>
             </div>

             <div className="text-center font-semibold mt-4" onClick={()=>{
               localStorage.clear()
               window.location.reload()
             }}>
              please if you're logged in log out first. Click me to do that !
               </div>
           </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminScreen;
