import {
  CampaignOutlined,
  CloudUpload,
  CloudUploadOutlined,
  KeyboardDoubleArrowLeft,
  Send,
  Upload,
} from "@mui/icons-material";
import { CircularProgress, Dialog, InputBase } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactSelect from "react-select";
import { useRecoilState } from "recoil";
import { select } from "../../react-select-styles";
import { user_idstate, user_namestate } from "../../state";
import Auth from "./Auth";
import AuthModal from "./AuthModal";

const NewHackModal = ({ open, setOpen }) => {
  const [isloading, setLoader] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [activeauth, setActiveAuth] = useState(0);
  const [msg, setMsg] = useState("loading categories ...");
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [name, setName] = useRecoilState(user_namestate);
  const [user_id, setUserId] = useRecoilState(user_idstate);

  const addHack = async () => {
    setLoader(true);
    let category_id = selectedCategory.value;
    let content = document.getElementById("hack").value;

    if (category_id.length < 2 || content.length < 3) {
      setMsg("Content field is required and necessary");
      setLoader(false);
      return;
    }
    setMsg("");
    let twitter_handle = null;
    // if(document.getElementById("twitter_handle").value.length > 2){
    //      twitter_handle = document.getElementById("twitter_handle").value
    // };
    let file = document.getElementById("image").files[0];

    let authorname = name;

    setMsg("uploading hack");
    let hack_id = "";

    await axios
      .post("https://hacks50-backend.herokuapp.com/api/hacks", {
        authorname,
        content,
        twitter_handle,
        category_id,
      })
      .then((res) => {
        console.log(res);
        hack_id = res.data.hack._id;

       
        if (!file) {
          setMsg(
            "Your parenting hack will be posted within 24 hours if approved"
          );
          setLoader(false);
          setTimeout(() => {
            setOpen(false);
          }, 4000);
        }
        
      })
      .catch((err) => {
        console.log(err);
        setMsg("some error occurred please try again");

        setLoader(false);
      });

    console.log(hack_id);

    if (file) {
      console.log(file);
      let formdata = new FormData();
      formdata.append("id", hack_id);
      formdata.append("file", file);
      formdata.append("for", "hack");
      await axios
        .post("https://hacks50-backend.herokuapp.com/api/file", formdata)
        .then((res) => {
          console.log(res);
          setLoader(false);
          setTimeout(() => {
            setOpen(false);
          }, 4000);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }

    setMsg("Babyhack loaded, you should see your hack within 24 hours.");
  };

  const authfromGoogle = async (response) => {};

  useEffect(() => {
    const getCategories = async () => {
      axios
        .get("https://hacks50-backend.herokuapp.com/api/category")
        .then((res) => {
          /*global google*/
          google.accounts.id.initialize({
            client_id:
              "679421255769-d3msap1il4q9uacvajn1f1fr90gpa85h.apps.googleusercontent.com",
            callback: authfromGoogle,
          });

          google.accounts.id.renderButton(
            document.getElementById("signInDiv"),
            {
              theme: "outline",
              size: "large",
            }
          );
          setLoader(false);

          setCategories(
            res.data.map((item, index) => ({
              value: item._id,
              label: item.name,
            }))
          );

          setSelectedCategory({
            value: res.data[0]._id,
            label: res.data[0].name,
          });

          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getCategories();
    setMsg("");
  }, [open]);

  return (
    <Dialog
      onClose={() => setOpen(false)}
      open={open}
      PaperProps={{
        sx: {
          bgcolor: "#e6e6e6",
        },
      }}
      maxWidth="100vw"
    >
      <AuthModal
        openAuthModal={openAuthModal}
        setOpenAuthModal={setOpenAuthModal}
      />
      {isloading ? (
        <div className="flex justify-center  lg:h-fit  py-3 px-3 ">
          <div>
            <div className="flex justify-center">
              {" "}
              <CircularProgress thickness={1} sx={{ color: "#e74c3c" }} />{" "}
            </div>
            <div className="lg:text-2xl text-xl font-bold tracking-tight text-center lg:px-3 text-gray-700">
              {" "}
              {msg}{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="  xl:w-[40vw] lg:w-[60vw]   pt-[10px] pb-[20px]  px-[8px] ">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Add your new babyhack !</title>
            <link rel="canonical" href="http://www.babyhack.co" />
          </Helmet>

          <div
            className=" text-[20px] font-semibold mb-2 lg:mb-8 cursor-pointer hover:bg-gray-300 w-fit px-1 rounded-[8px] "
            onClick={() => setOpen(false)}
          >
            <KeyboardDoubleArrowLeft sx={{ position: "relative", bottom: 2 }} />
            Go back
          </div>

          <div className="text-yellow-600 text-[14px] font-semibold ">
            {msg && msg.length > 1 && (
              <>
                <CampaignOutlined /> {msg}
              </>
            )}
          </div>

          <div className="lg:text-4xl text-2xl font-bold tracking-tight text-left lg:px-3 text-gray-700  my-4 ">
            Share your baby hack
          </div>

          <div className="border-[1px] border-[rgb(156,163,175,0.6)]  rounded-[12px] mb-3  ">
            <ReactSelect
              options={categories}
              value={selectedCategory}
              onChange={(option) => {
                setSelectedCategory(option);
              }}
              components={{
                DropdownIndicator: select.DropdownIndicator,
                IndicatorSeparator: () => null,
                Placeholder: select.Placeholder,
              }}
              styles={select.style}
              placeholder="select a category"
            />
          </div>
          <div>
            <div className="text-[14px]">
              Your hack <span className="text-red-500">*</span>
            </div>

            <InputBase
              sx={{
                border: "1px solid rgb(156,163,175,0.6)",
                bgcolor: "white",
                pl: 3,
                pt: 2,
                width: "100%",
                borderRadius: "12px",
                fontSize: { lg: "15px", xs: "15px" },
                fontWeight: 500,
              }}
              placeholder="Want to add something?"
              multiline={true}
              id="hack"
              rows={6}
              onChange={(e) => {
                if (e.target.value.length < 241) {
                  setCharCount(e.target.value.length);
                } else {
                  e.target.value = e.target.value.substr(0, 240);
                }
              }}
            />

            <div className="absolute right-5 text-[10px]   lg:text-sm font-semibold   ">
              {" "}
              {charCount} /240
            </div>

            <div className="pl-3 my-3">
              <CloudUploadOutlined
                sx={{ color: "#c6301f" }}
                onClick={() => {
                  document.getElementById("image").click();
                }}
              />{" "}
              <span
                id="image_name"
                onClick={() => {
                  document.getElementById("image").click();
                }}
              >
                add an image/video
              </span>
              <input
                hidden
                accept="image/*, video/*"
                id="image"
                type="file"
                onChange={(e) => {
                  document.getElementById("image_name").innerHTML =
                    e.target.files[0].name;
                  if (e.target.files[0].type.substr(0, 5) === "image") {
                    document.getElementById("preview").style.display = "block";
                    document.getElementById("preview").src =
                      URL.createObjectURL(e.target.files[0]);
                    document.getElementById("preview2").style.display = "none";
                  }
                  if (e.target.files[0].type.substr(0, 5) === "video") {
                    document.getElementById("preview2").style.display = "block";
                    document.getElementById("preview2").src =
                      URL.createObjectURL(e.target.files[0]);
                    document.getElementById("preview").style.display = "none";
                  }
                }}
              />
              <img id="preview" className="w-[240px] h-full" />
              <video
                id="preview2"
                style={{ display: "none" }}
                className="w-full h-[200px]"
                controls
              />
            </div>
          </div>

          <div className="space-y-3 mt-2">
            {/* <Auth activeauth={activeauth} setActiveAuth={setActiveAuth} user_id={user_id} setName={setName}
            setLoader={setLoader} setMsg={setMsg} setUserId={setUserId} 
            /> */}

            {/* <div className="w-full">
              <div className="text-[14px]">Show your Twitter ?</div>
              <InputBase
                startAdornment={
                  <div
                    className="bg-[rgb(156,163,175,0.6)] absolute left-0  rounded-tl-[6px]
                 rounded-bl-[6px] h-full pt-[6.5px] px-[7px] "
                  >
                    @
                  </div>
                }
                sx={{
                  border: "1px solid rgb(156,163,175,0.6)",
                  bgcolor: "white",
                  pl: 4,
                  py: "4px",
                  width: "100%",
                  borderRadius: "7px",
                  fontSize: { lg: "15px", xs: "15px" },
                  fontWeight: 500,
                }}
                placeholder="e.g DanielCleaves"
                id="twitter_handle"
              />
            </div> */}
          </div>

          <div
            className="bg-[#e74c3c] hover:bg-[#b42c1c]   w-full justify-center mt-10 flex items-center text-white lg:py-[8px] py-[8px]
          px-[4px] lg:px-[8px] cursor-pointer rounded-[6px]  "
            onClick={() => {
              if (name) {
                addHack();
              } else {
                setOpenAuthModal(true);
              }
            }}
          >
            <div>
              {" "}
              Send <Send sx={{ color: "white" }} />{" "}
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default NewHackModal;
// const categories = [
//   {
//     value: "health_lifestyle",
//     label: "Health and LifeStyle",
//   },
//   {
//     value: "Education",
//     label: "Education",
//   },
//   {
//     value: "Wealth and money",
//     label: "Wealth and Money",
//   },
// ];
