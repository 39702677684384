import {
  CampaignOutlined,
  CloudUploadOutlined,
  Delete,
  SettingsRemoteOutlined,
  Twitter,
} from "@mui/icons-material";
import { CircularProgress, InputBase } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { bogusCheck } from "../../high_functions";
import { user_idstate, user_namestate } from "../../state";
import Auth from "./Auth";
import AuthModal from "./AuthModal";
import Reply from "./Reply";

const CommentData = ({ hack, comment,discardComment }) => {
  const [replyInput, setShowReplyInput] = useState(false);
  const [replies, setShowReplies] = useState(false);

  const [replyData, setReplyData] = useState([]);

  const [activeauth, setActiveAuth] = useState(0);
  const [msg, setMsg] = useState("loading categories ...");
  const [name, setName] = useRecoilState(user_namestate);
  const [user_id, setUserId] = useRecoilState(user_idstate);
  const [isloading, setLoader] = useState(true);

  const [likes, setLikes] = useState(comment.likes);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [msgm, setMsgm] = useState(null);

  const createReport = (comment_id, comment,username,img) => {
    setLoader(true);
    
    axios.post("https://hacks50-backend.herokuapp.com/api/report",{
      comment_id,comment,username,img
    })
    .then((res)=> {
      console.log(res)
      document.getElementById(comment_id+'-com').innerHTML = 'Reported'
      document.getElementById(comment_id+'-com').onclick = ()=> null
      setLoader(false);
    })

    .catch((err)=>{
      console.log(err)
      setLoader(false);
    })

  }

  const discardReply = async(reply_id) => {
    setLoader(true);
    axios.get("https://hacks50-backend.herokuapp.com/api/reply/discard/"+reply_id)
    
    .then((res)=>{
      console.log(res)
      getReplies()
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  const react = (reaction) => {
    axios
      .post("https://hacks50-backend.herokuapp.com/api/comment/react", {
        sub_id: comment._id,
        user_id,
        reaction,
      })

      .then((res) => {
        console.log(res);
        setLikes(res.data.comment.likes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReplies = () =>{
    axios
    .get("https://hacks50-backend.herokuapp.com/api/reply/" + comment._id)
    .then((res) => {
      setReplyData(res.data);
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
  getReplies()
  }, [comment]);




  const addReply = async () => {
    setLoader(true);
    let file = document.getElementById("image_for_reply").files[0];
    let twitter_handle = null;
    let reply_id = "";
    // if(document.getElementById('twitter_handle').value.length > 2){
    //   twitter_handle = document.getElementById('twitter_handle').value
    // }


    



    if (document.getElementById("reply").value.length < 1) {
      setMsgm("Reply field is required and necessary");
      setLoader(false);
      return;
    }


    if(bogusCheck(document.getElementById("reply").value)){
      setMsgm("foul/swear words detected, pls remove");
      setLoader(false);
      return
    }

    



    setMsgm(null);
    await axios
      .post("https://hacks50-backend.herokuapp.com/api/reply/", {
        user_id : user_id,
        comment_id: comment._id,
        username: name,
        reply: document.getElementById("reply").value,
        twitter_handle,
      })
      .then((res) => {
       
        console.log(res.data);
        if(!file){
          setReplyData((currentItems) => [...currentItems, res.data.reply]);
          setLoader(false);
        }
        reply_id = res.data.reply._id;
        setShowReplyInput(false);
      })

      .catch((err) => {
        console.log(err);
        setLoader(false);
      });

    if (file) {
      console.log("hi");
      let formdata = new FormData();
      formdata.append("id", reply_id);
      formdata.append("file", file);
      formdata.append("for", "reply");
      await axios
        .post("https://hacks50-backend.herokuapp.com/api/file", formdata)
        .then((res) => {
          console.log(res);
          setReplyData((currentItems) => [...currentItems, res.data.reply]);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="flex space-x-3 ">
      <AuthModal
        openAuthModal={openAuthModal}
        setOpenAuthModal={setOpenAuthModal}
      />
      <div className="text-[13px] lg:text-[16px] ">
        <div
          className="cursor-pointer"
          onClick={() => {
            if (user_id) {
              react(1);
              console.log("hi");
            } else {
              setOpenAuthModal(true);
            }
          }}
        >
           😇
        </div>
        <div className="text-center font-bold "> {likes}</div>
        <div
          className="cursor-pointer"
          onClick={() => {
            if (user_id) {
              react(-1);
            } else {
              setOpenAuthModal(true);
            }
          }}
        >
          🤔
        </div>
      </div>

      <div className=" space-y-2 ">
        <div className="text-[14px] lg:text-[17px] ">
          <div className="flex items-start">
            <div className="flex-1">{comment.comment} </div>
            {comment.file_type && comment.file_type.substr(0,5) === 'image' && <img src={comment.file} onClick={()=> window.open(comment.file, '_blank')} className="w-[50px] h-[40px] rounded-md " />}
            {comment.file_type && comment.file_type.substr(0,5) === 'video' && <video src={comment.file} onClick={()=> window.open(comment.file, '_blank')} className="w-[50px] h-[40px] rounded-md " />}
            {/* {comment.img && (
              <img
                src={comment.img}
                onClick={() => window.open(comment.img, "_blank")}
                className="w-[50px] h-[40px] rounded-md "
              />
            )} */}
          </div>{" "}
        </div>

        <div className=" text-[12px] text-[#9197a0]  ">
          <span>
            By
            <span className=" hover:text-[#e74c3c] cursor-pointer ">
              {" "}
              {comment.twitter_handle ? (
                <>
                  <Twitter sx={{ fontSize: "16px" }} />
                  {comment.twitter_handle}
                </>
              ) : (
                comment.username
              )}{" "}
            </span>
            <span> {moment(comment.createdAt).fromNow()}</span>
            <span
            id={comment._id+'-com'}
              className="text-black hover:border-[#b42c1c] ml-2 border-black cursor-pointer px-[3px] py-[2px] rounded-[3px] "
              onClick={() => {
               createReport(comment._id, comment.comment, comment.username, comment.img)
              }}
            >
              Report
            </span>
            <span
              className="ml-5 text-black mr-1 hover:bg-gray-300  cursor-pointer px-[1px] py-[2px]
             rounded-[3px] "
              onClick={() => {
                setShowReplies(!replies);
              }}
            >
              {" "}
              {replies ? "[-]" : `[${replyData.length} MORE]`}{" "}
            </span>
            <span
              className="text-black hover:bg-gray-300  cursor-pointer px-[3px] py-[2px] rounded-[3px] "
              onClick={() => {
                setShowReplyInput(!replyInput);
              }}
            >
              REPLY
            </span>

            
            <span
              className="text-black hover:text-[#e74c3c]  cursor-pointer px-[3px] py-[2px] rounded-[3px] "
            >
             {comment.user_id === user_id && (
               <Delete sx={{ fontSize:{lg:'25px', xs:'17px'} }}  onClick={()=> discardComment(comment._id)} />
             )}
            </span>
          </span>
        </div>

        {replyInput && (
          <div>
            <div className="text-yellow-600 text-[14px] font-semibold ">
              {msgm && msgm.length > 1 && (
                <>
                  <CampaignOutlined /> {msgm}
                </>
              )}
            </div>
            <div>
              <InputBase
                sx={{
                  border: "1px solid rgb(156,163,175,0.6)",
                  bgcolor: "white",
                  pl: 3,
                  pt: 2,
                  width: { xs: "300px" },
                  borderRadius: "12px",
                  fontSize: { lg: "13px", xs: "13px" },
                  fontWeight: 500,
                }}
                placeholder="add your comment"
                multiline={true}
                rows={6}
                id="reply"
              />
            </div>

            <div className="mt-2 space-y-3">
              {/* <Auth
                    activeauth={activeauth}
                    setActiveAuth={setActiveAuth}
                    user_id={user_id}
                    setName={setName}
                    setLoader={setLoader}
                    setMsg={setMsg}
                    setUserId={setUserId}
                  /> */}
            </div>

            <div className="flex space-x-3 mt-3">
              <div>
                <div className="pl-3 ">
                  <CloudUploadOutlined
                    sx={{ color: "#c6301f" }}
                    onClick={() => {
                      document.getElementById("image_for_reply").click();
                    }}
                  />{" "}
                  <span
                    id="image_name"
                    className=""
                    onClick={() => {
                      document.getElementById("image_for_reply").click();
                    }}
                  >
                    add an image/video
                  </span>
                  <input
                    hidden
                    accept="image/*, video/*"
                    id="image_for_reply"
                    type="file"
                    onChange={(e) => {
                      document.getElementById("image_name").innerHTML =
                        e.target.files[0].name.substr(0, 20) + "..";

                        if(e.target.files[0].type.substr(0,5) === 'image'){
                          document.getElementById('preview').style.display = 'block'
                          document.getElementById('preview').src = URL.createObjectURL(e.target.files[0])
                          document.getElementById('preview2').style.display = 'none'
                          
                        }
                        if(e.target.files[0].type.substr(0,5) === 'video'){
                          document.getElementById('preview2').style.display = 'block'
                          document.getElementById('preview2').src = URL.createObjectURL(e.target.files[0])
                          document.getElementById('preview').style.display = 'none'
                          
                        }
                    }}
                  />
                   <img id="preview" style={{display: 'none'}}  className="w-[100px] h-[70px] border-transparent " />
                   <video id='preview2' style={{ display:'none'}} className="w-full h-[200px]" controls />
                </div>
              </div>

              {/* <div>
              <div className="text-[14px]">Show your Twitter ?</div>
              <InputBase
                startAdornment={
                  <div
                    className="bg-[rgb(156,163,175,0.6)] absolute left-0  rounded-tl-[11px]
                 rounded-bl-[11px] h-full pt-[6.5px] px-[7px] "
                  >
                    @
                  </div>
                }
                sx={{
                  border: "1px solid rgb(156,163,175,0.6)",
                  bgcolor: "white",
                  pl: 4,
                  py: "2px",
                  width: "100%",
                  borderRadius: "12px",
                  fontSize: { lg: "13px", xs: "13px" },
                  fontWeight: 500,
                }}
                placeholder="e.g DanielCleaves"
                id='twitter_handle'
              />
            </div> */}
            </div>

            <div className="flex items-center space-x-2">
              <div
                className="bg-[#e74c3c] hover:bg-[#b42c1c]   w-fit flex items-center text-white lg:py-[8px] py-[8px]
          px-[4px] lg:px-[8px] cursor-pointer rounded-[8px] mt-3 text-[14px] "
                onClick={() => {
                  if (name) {
                    addReply();
                  } else {
                    setOpenAuthModal(true);
                  }
                }}
              >
                <div> Add Reply </div>
              </div>

              <div
                className=" hover:bg-gray-300   w-fit flex items-center text-black lg:py-[8px] py-[8px]
          px-[4px] lg:px-[8px] cursor-pointer rounded-[8px] mt-3 text-[14px] "
                onClick={() => {
                  setShowReplyInput(false);
                }}
              >
                <div> Cancel </div>
              </div>
            </div>
          </div>
        )}

        {replies && (
          <div className="pl-3 pt-3 ">
            {isloading ? (
              <div className="flex justify-center h-[70vw] lg:h-fit  mt-[20%] ">
                <div>
                  <div className="flex justify-center">
                    {" "}
                    <CircularProgress
                      thickness={1}
                      sx={{ color: "#e74c3c" }}
                    />{" "}
                  </div>
                  <div className="lg:text-2xl text-xl font-bold tracking-tight text-center lg:px-3 text-gray-700">
                    {" "}
                    loading replies ...{" "}
                  </div>
                </div>
              </div>
            ) : (
              <>
                {replyData &&
                  replyData.length > 0 &&
                  replyData.map((item, index) => (
                    <div key={index}>
                      {" "}
                      <Reply reply={item} discardReply={discardReply} />{" "}
                    </div>
                  ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentData;
