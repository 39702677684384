import { CircularProgress, Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useRecoilState } from "recoil";
import { user_idstate, user_namestate } from "../../state";
import Auth from "./Auth";

const AuthModal = ({ openAuthModal, setOpenAuthModal }) => {
  const [activeauth, setActiveAuth] = useState(0);
  const [msg, setMsg] = useState("loading categories ...");
  const [name, setName] = useRecoilState(user_namestate);
  const [user_id, setUserId] = useRecoilState(user_idstate);
  const [isloading, setLoader] = useState(false);
  const [msgm, setMsgm] = useState("")

  return (
    <Dialog
      onClose={() => setOpenAuthModal(false)}
      open={openAuthModal}
      PaperProps={{
        sx: {
          bgcolor: "#e6e6e6",
        },
      }}
      maxWidth="100vw"
    >
      <div className="px-3  lg:px-3 py-2 lg:py-3 lg:w-[30vw] w-[70vw] ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>😎/🤔 -Authentication</title>
        <link rel="canonical" href="https://mysite.com/example" />
      </Helmet>
        {isloading ? (
          <div className="flex justify-center   ">
            <div>
              <div className="flex justify-center">
                {" "}
                <CircularProgress
                  thickness={1}
                  sx={{ color: "#e74c3c" }}
                />{" "}
              </div>
              <div className="lg:text-2xl text-xl font-bold tracking-tight text-center lg:px-3 text-gray-700">
                {" "}
                authenticating...{" "}
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-3 mt-2">
            <Auth
              activeauth={activeauth}
              setActiveAuth={setActiveAuth}
              user_id={user_id}
              setName={setName}
              setLoader={setLoader}
              setMsg={setMsg}
              setUserId={setUserId}
              setOpenAuthModal={setOpenAuthModal}
              msg={msg}
              msgm={msgm}
              setMsgm = {setMsgm}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default AuthModal;
