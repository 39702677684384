import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/molecules/Header";
import AdminScreen from "./components/screens/AdminScreen";
import HomeScreen from "./components/screens/HomeScreen";

const Main = () => {
  return (
    <div className="flex justify-center">
      <div className="lg:w-[40vw] w-full  lg:px-0 pt-3 pb-10 lg:py-8 ">
        <Header/>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/hack/:hack_id" element={<HomeScreen />} />
            <Route path='/admin/*' element={<AdminScreen/>} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
};

export default Main;
