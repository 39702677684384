import { Delete, Twitter } from '@mui/icons-material'
import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { user_idstate } from '../../state'
import AuthModal from './AuthModal'

const Reply = ({reply,discardReply}) => {
  
  const [likes, setLikes] = useState(reply.likes)
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [user_id, setUserId] = useRecoilState(user_idstate);





  const react = (reaction) =>{

    axios.post("https://hacks50-backend.herokuapp.com/api/reply/react", {
      sub_id : reply._id,
      user_id,
      reaction

    })

    .then((res)=>{
      console.log(res)
      setLikes(res.data.reply.likes)
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  return (
    <div className="flex space-x-3 ">
      <AuthModal openAuthModal={openAuthModal} setOpenAuthModal={setOpenAuthModal}  />
      <div className="text-[13px] lg:text-[16px] ">
        <div className="cursor-pointer"
         onClick={()=>{
          if(user_id){
            react(1)
            console.log('hi')
          }else{
            setOpenAuthModal(true)
          }
        }}
        > 😇</div>
        <div className="text-center font-bold "> {likes}</div>
        <div className="cursor-pointer"
         onClick={()=>{
          if(user_id){
            react(-1)
          }else{
            setOpenAuthModal(true)
          }
        }}
        >🤔</div>
      </div>

      <div className=" space-y-2 ">
        <div className="text-[14px] lg:text-[17px] ">
          {" "}
          <div className="flex items-start">
            <div className="flex-1" >{reply.reply}{" "}</div>
            {reply.file_type && reply.file_type.substr(0,5) === 'image' && <img src={reply.file} onClick={()=> window.open(reply.file, '_blank')} className="w-[50px] h-[40px] rounded-md " />}
            {reply.file_type && reply.file_type.substr(0,5) === 'video' && <video src={reply.file} onClick={()=> window.open(reply.file, '_blank')} className="w-[50px] h-[40px] rounded-md " />}
          </div>
        
        </div>

        <div className=" text-[12px] text-[#9197a0]  ">
          <span>
            By
            <span className=" hover:text-[#e74c3c] cursor-pointer ">
              {" "}
            {reply.twitter_handle ? (
                  <>
                    <Twitter sx={{ fontSize: "16px" }} />
                    {reply.twitter_handle}
                  </>
                ) : (
                  reply.username
                )}
                {" "}
            </span>
            <span> {moment(reply.createdAt).fromNow()} </span>
            <span
              className="text-black hover:text-[#e74c3c]  cursor-pointer px-[3px] py-[2px] rounded-[3px] "
            >
             {reply.user_id === user_id && (
               <Delete sx={{ fontSize:{lg:'25px', xs:'17px'} }} onClick={()=> discardReply(reply._id)} />
             )}
            </span>
          </span>
        </div>

        
      </div>
    </div>
  )
}

export default Reply