import {
  CampaignOutlined,
  CloudUploadOutlined,
  DoubleArrowOutlined,
  KeyboardDoubleArrowLeft,
  Twitter,
} from "@mui/icons-material";
import { CircularProgress, Divider, Drawer, InputBase } from "@mui/material";
import React, { useEffect, useState } from "react";
import Hack from "./Hack";
import Header from "./Header";

import { Helmet } from "react-helmet";
import Share from "./Share";
import CommentData from "./CommentData";
import HackContent from "./HackContent";
import axios from "axios";
import Auth from "./Auth";
import { useRecoilState } from "recoil";
import { user_idstate, user_namestate } from "../../state";
import { useNavigate, useParams } from "react-router-dom";
import AuthModal from "./AuthModal";
import { bogusCheck } from "../../high_functions";

const HackDetailModal = ({ open, setOpen, hack }) => {
  const [commentLoader, setCommentLoader] = useState(true);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [comments, setComments] = useState([]);
  const [activeauth, setActiveAuth] = useState(0);
  const [msg, setMsg] = useState("loading categories ...");
  const [showAddComment, setShowAddComment] = useState(false);
  const [name, setName] = useRecoilState(user_namestate);
  const [user_id, setUserId] = useRecoilState(user_idstate);
  const [msgm, setMsgm] = useState(null);

  const navigate = useNavigate();

  const [isloading, setLoader] = useState(true);

  const discardComment = async(comment_id) => {
    setLoader(true);
    axios.get("https://hacks50-backend.herokuapp.com/api/comment/discard/"+comment_id)
    
    .then((res)=>{
      console.log(res)
      getComments()
    })
    .catch((err)=>{
      console.log(err)
    })
  }


  const getComments = () => {
    setCommentLoader(true);
    axios
      .get("https://hacks50-backend.herokuapp.com/api/comment/" + hack._id)
      .then((res) => {
        setCommentLoader(false);
        setComments(res.data);
      })

      .catch((err) => {
        console.log(err);
        setCommentLoader(false);
      });
  };
  useEffect(() => {
    

    getComments();
  }, []);

  const addComment = async () => {
    setCommentLoader(true);
    let twitter_handle = null;
    let file = document.getElementById("image").files[0];
    // if (document.getElementById("twitter_handle").value.length > 2) {
    //   twitter_handle = document.getElementById("twitter_handle").value;
    // }
    let comment_id = "";
    if (document.getElementById("comment").value.length < 1) {
      setMsgm("Comment field is required and necessary");
      setCommentLoader(false);
      return;
    }

    if(bogusCheck(document.getElementById("comment").value)){
      setMsgm("foul/swear words detected, pls remove");
      setCommentLoader(false);
      return
    }
    setMsgm(null);
    await axios
      .post("https://hacks50-backend.herokuapp.com/api/comment/", {
        user_id : user_id,
        hack_id: hack._id,
        username: name,
        comment: document.getElementById("comment").value,
        twitter_handle,
      })
      .then((res) => {
        console.log(res.data);
        comment_id = res.data.comment._id;
        if (!file) {
          setComments((currentItems) => [...currentItems, res.data.comment]);
          setCommentLoader(false);
        }
        setShowAddComment(false);
      })

      .catch((err) => {
        console.log(err);
        setCommentLoader(false);
      });

    if (file) {
      console.log("hi");
      let formdata = new FormData();
      formdata.append("id", comment_id);
      formdata.append("file", file);
      formdata.append("for", "comment");
      await axios
        .post("https://hacks50-backend.herokuapp.com/api/file", formdata)
        .then((res) => {
          console.log(res);
          setComments((currentItems) => [...currentItems, res.data.comment]);
          setCommentLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <React.Fragment>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        anchor={"right"}
        PaperProps={{
          sx: {
            bgcolor: "#e6e6e6",
          },
        }}
      >
        <div className=" w-[100vw] lg:w-[50vw] xl:w-[40vw]  py-4 px-2 max-h-fit  ">
          <AuthModal
            openAuthModal={openAuthModal}
            setOpenAuthModal={setOpenAuthModal}
          />
          <Helmet>
            <meta charSet="utf-8" />
            <title>😎/🤔 - {hack.content}</title>
            <link rel="canonical" href="http://50hacks.vercel.app/" />
          </Helmet>

          <div className="mb-7">
            <Header />
          </div>

          <div
            className=" text-[20px] font-semibold mb-8 cursor-pointer "
            onClick={() => {
              setOpen(false);
              navigate("/");
            }}
          >
            <KeyboardDoubleArrowLeft />
            Go back
          </div>

          <div className="mb-5">
            <HackContent hack={hack} comments={comments} />
          </div>

          <div className="flex items-center justify-center  mb-16 ">
            <Share hack={hack} />
          </div>

          <div>
            {commentLoader ? (
              <div className="flex justify-center h-[70vw] lg:h-fit  mt-[20%] ">
                <div>
                  <div className="flex justify-center">
                    {" "}
                    <CircularProgress
                      thickness={1}
                      sx={{ color: "#e74c3c" }}
                    />{" "}
                  </div>
                  <div className="lg:text-2xl text-xl font-bold tracking-tight text-center lg:px-3 text-gray-700">
                    {" "}
                    loading comments ...{" "}
                  </div>
                </div>
              </div>
            ) : (
              <>
                {comments && comments.length < 1 ? (
                  <div>No comments , be the first to comment</div>
                ) : (
                  <div className="mt-2 space-y-3 mb-2">
                    {comments.map((item, index) => (
                      <div key={index}>
                        <CommentData hack={hack} comment={item} discardComment={discardComment} />
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            {showAddComment ? (
              <>
                <div className="text-yellow-600 text-[14px] font-semibold ">
                  {msgm && msgm.length > 1 && (
                    <>
                      <CampaignOutlined /> {msgm}
                    </>
                  )}
                </div>
                <div>
                  <InputBase
                    sx={{
                      border: "1px solid rgb(156,163,175,0.6)",
                      bgcolor: "white",
                      pl: 3,
                      pt: 2,
                      width: "100%",
                      borderRadius: "12px",
                      fontSize: { lg: "13px", xs: "13px" },
                      fontWeight: 500,
                    }}
                    placeholder="add your parenting hack"
                    multiline={true}
                    rows={6}
                    id="comment"
                  />
                </div>

                <div className="mt-2 space-y-3">
                  {/* <Auth
                    activeauth={activeauth}
                    setActiveAuth={setActiveAuth}
                    user_id={user_id}
                    setName={setName}
                    setLoader={setLoader}
                    setMsg={setMsg}
                    setUserId={setUserId}
                  /> */}
                </div>

                <div className="flex space-x-3 mt-2">
                  <div className="w-full">
                    <div className="pl-3 my-3">
                      <CloudUploadOutlined
                        sx={{ color: "#c6301f" }}
                        onClick={() => {
                          document.getElementById("image").click();
                        }}
                      />{" "}
                      <span
                        id="image_name"
                        onClick={() => {
                          document.getElementById("image").click();
                        }}
                      >
                        add an image/video
                      </span>
                      <input
                        hidden
                        accept="image/*, video/*"
                        id="image"
                        type="file"
                        onChange={(e) => {
                          document.getElementById("image_name").innerHTML =
                            e.target.files[0].name;

                            if(e.target.files[0].type.substr(0,5) === 'image'){
                              document.getElementById('preview').style.display = 'block'
                              document.getElementById('preview').src = URL.createObjectURL(e.target.files[0])
                              document.getElementById('preview2').style.display = 'none'
                              
                            }
                            if(e.target.files[0].type.substr(0,5) === 'video'){
                              document.getElementById('preview2').style.display = 'block'
                              document.getElementById('preview2').src = URL.createObjectURL(e.target.files[0])
                              document.getElementById('preview').style.display = 'none'
                              
                            }
                        }}
                      />
                      <img id="preview" style={{display: 'none'}}  className="w-[140px] h-[90px] border-transparent " />
                      <video id='preview2' style={{ display:'none'}} className="w-full h-[200px]" controls />
                    </div>
                  </div>

                  {/* <div className="w-full">
                    <div className="text-[14px]">Show your Twitter ?</div>
                    <InputBase
                      startAdornment={
                        <div
                          className="bg-[rgb(156,163,175,0.6)] absolute left-0  rounded-tl-[11px]
   rounded-bl-[11px] h-full pt-[6.5px] px-[7px] "
                        >
                          @
                        </div>
                      }
                      sx={{
                        border: "1px solid rgb(156,163,175,0.6)",
                        bgcolor: "white",
                        pl: 4,
                        py: "4px",
                        width: "100%",
                        borderRadius: "12px",
                        fontSize: { lg: "13px", xs: "13px" },
                        fontWeight: 500,
                      }}
                      placeholder="e.g DanielCleaves"
                      id='twitter_handle'
                    />
                  </div> */}
                </div>

                <div className="flex items-center space-x-4">
                  <div
                    className="bg-[#e74c3c] hover:bg-[#b42c1c]   w-fit flex items-center text-white lg:py-[8px] py-[8px]
px-[4px] lg:px-[8px] cursor-pointer rounded-[8px] mt-3 "
                    onClick={() => {
                      if (name) {
                        addComment();
                      } else {
                        setOpenAuthModal(true);
                      }
                    }}
                  >
                    <div> Add Comment </div>
                  </div>

                  <div
                    className=" hover:bg-[#464646]   w-fit flex items-center text-gray-900 lg:py-[8px] py-[8px]
px-[4px] lg:px-[8px] cursor-pointer rounded-[8px] mt-3 "
                    onClick={() => setShowAddComment(false)}
                  >
                    <div> Cancel </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                className="bg-[#e74c3c] hover:bg-[#b42c1c]   w-full text-center  text-white lg:py-[8px] py-[8px]
  px-[4px] lg:px-[8px] cursor-pointer rounded-[8px] mt-3"
                onClick={() => setShowAddComment(true)}
              >
                {" "}
                hey! friend add a comment{" "}
              </div>
            )}
          </div>

          <div>
            <Divider sx={{ borderBottom: "", mt: 2 }} />
          </div>
          <div className="text-center italic text-[14px] text-gray-500 mb-4 ">
            {" "}
            the end{" "}
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default HackDetailModal;
