import { Delete } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const Reports = () => {
  const [loader, setLoader] = useState(true);
  const [reports, setReports] = useState(null);

  const discardComment = async (comment_id) => {
    setLoader(true);
    axios
      .get(
        "https://hacks50-backend.herokuapp.com/api/comment/discard/" +
          comment_id
      )

      .then((res) => {
        console.log(res);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const clearReport = (report_id) => {
    setLoader(true)
    axios
      .get(
        "https://hacks50-backend.herokuapp.com/api/report/clear/" + report_id
      )
      .then((res) => {
        console.log(res);
        getReports();
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const getReports = () => {
    setLoader(true);
    axios
      .get("https://hacks50-backend.herokuapp.com/api/report/")
      .then((res) => {
        console.log(res);
        setLoader(false);
        setReports(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getReports();
  }, []);

  return (
    <div>
      {loader ? (
        <div className="flex justify-center h-[70vw] lg:h-fit  mt-[20%] ">
          <div>
            <div className="flex justify-center">
              {" "}
              <CircularProgress thickness={1} sx={{ color: "#e74c3c" }} />{" "}
            </div>
            <div className="lg:text-2xl text-xl font-bold tracking-tight text-center lg:px-3 text-gray-700">
              {" "}
              loading ...{" "}
            </div>
          </div>
        </div>
      ) : (
        <div>
          {reports && reports.length > 0 ? (
            <>
              {reports.map((item, index) => (
                <div className="bg-white px-2 py-[4px] rounded-lg border-2 border-[#e6e6e6] flex  ">
                  <div className="flex-1">
                    <div className="text-lg font-semibold text-gray-900">
                      {index + 1} {item.comment}
                    </div>
                    <div className=" text-[12px] text-[#9197a0]  ">
                      <span>
                        By{" "}
                        <span className=" hover:text-[#e74c3c] cursor-pointer ">
                          {item.username}
                        </span>
                        <span className="text-black hover:border-[#b42c1c] ml-2 border-black cursor-pointer px-[3px] py-[2px] rounded-[3px] ">
                          Reported {moment(item.createdAt).fromNow()}
                        </span>
                        <span className="text-gray-400 hover:border-[#b42c1c] ml-2 border-black cursor-pointer px-[3px] py-[2px] rounded-[3px] ">
                          status : uncleared
                        </span>
                      </span>
                    </div>
                  </div>

                  <div
                    className="mr-4 hover:text-red-700 cursor-pointer "
                    onClick={() => clearReport(item._id)}
                  >
                    clear
                  </div>

                  <div>
                    <span className="text-black hover:text-[#e74c3c]  cursor-pointer px-[3px] py-[2px] rounded-[3px] ">
                      <Delete
                        sx={{ fontSize: { lg: "30px", xs: "27px" } }}
                        onClick={() => discardComment(item._id)}
                      />
                    </span>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className='text-center' > 😃 There are no uncleared reports</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Reports;
