import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { user_idstate } from "../../../state";
import AdminHack from "../../molecules/AdminHack";

const Hacks = ({loading, setLoader,hacks, setHacks}) => {
    const [user_id, setUserId] = useRecoilState(user_idstate);
   
  return (
    <>
      {hacks && hacks.length < 1 ? (
        <div> No Pending Hacks </div>
      ) : (
        <div className="space-y-3">
          {hacks.map((item, index) => (
            <AdminHack hack={item} key={index} index={index} />
          ))}
        </div>
      )}
    </>
  );
};

export default Hacks;
