import { InputBase } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { Announcement, CampaignOutlined } from '@mui/icons-material';

const Auth = ({user_id,activeauth, setActiveAuth,setName, setLoader, setMsg, setUserId, setOpenAuthModal, msg,msgm, setMsgm}) => {
  
    const register = () => {
        setLoader(true);
        setMsg('authenticating user')
        let email = document.getElementById("email").value;
        let password = document.getElementById("password").value;
        let name = document.getElementById("name").value;
        if(email.length < 2 || password.length < 2 || name.length < 2){
          setMsgm('All feilds are required and necessary')
          setLoader(false)
          return
        }
        axios
          .post("https://hacks50-backend.herokuapp.com/api/users", {
            name,
            email,
            password,
          })
          .then((res) => {
         
            console.log(res);
            localStorage.setItem("user_id", res.data._id);
            localStorage.setItem("user_name", res.data.name);
            setUserId(res.data._id);
            setName(res.data.name);
    
            setLoader(false);
            setMsg("yeah! continue to post your hack");
            setOpenAuthModal(false)
          })
          .catch((err) => {
            console.log(err);
          });
      };
    
    
      const login = () => {
        setLoader(true);
        setMsg('authenticating user')
        let email = document.getElementById("email").value;
        let password = document.getElementById("password").value;
        
        axios
          .post("https://hacks50-backend.herokuapp.com/api/users/get-user", {
            email,
            password,
          })
          .then((res) => {

            console.log(res)
            
            if(res.data.code === 1 ){
              setMsgm('this account uses google login please login with google')
              setLoader(false)
              return
              
            }
            if(res.data.code === 2 ){
              setMsgm(res.data.msg)
              setLoader(false)
              console.log(res)
              return
              
            } 
            if(res.data.code === 3 ){
              setMsgm(res.data.msg)
              setLoader(false)
              console.log(res)
              return
              
            } 
            localStorage.setItem("user_id", res.data._id);
            localStorage.setItem("user_name", res.data.name);
            setUserId(res.data._id);
            setName(res.data.name);
    
            setLoader(false);
            setMsg("yeah! continue to post your hack");
            setOpenAuthModal(false)
          })
          .catch((err) => {
            console.log(err);
          });
      };

      const authfromGoogle = async (response) => {
        console.log(response)
        const user = jwt_decode(response.credential)
        console.log(user)

        axios
          .post("https://hacks50-backend.herokuapp.com/api/users/google-auth", {
            email: user.email,
            password : user.email,
            google_login : true,
            name: user.name
          })
          .then((res) => {
           
            if(res.data.code === 1 ){
              setMsgm('this account uses manual login please login maunually')
              setLoader(false)
              console.log(res)
             
              return
              
            } 
            localStorage.setItem("user_id", res.data._id);
            localStorage.setItem("user_name", res.data.name);
            setUserId(res.data._id);
            setName(res.data.name);
            setLoader(false);
            setMsg("yeah! continue to post your hack");
            setOpenAuthModal(false)
          })
          .catch((err) => {
            console.log(err);
          });
      };

      useEffect(() => {
         /*global google*/
         google.accounts.id.initialize({
          client_id:
            "679421255769-d3msap1il4q9uacvajn1f1fr90gpa85h.apps.googleusercontent.com",
          callback: authfromGoogle,
        });
  
        google.accounts.id.renderButton(
          document.getElementById("signInDiv"),
          {
            theme: "outline",
            size: "large",
          }
        );
      }, [])
  return (
    <div>
        {!user_id && (
              <div className='space-y-2'>
                <div className=" items-center grid grid-cols-2  ">
                  <div
                    className={
                      activeauth === 0
                        ? "bg-gray-900 text-white py-1 text-md font-semibold rounded-tl-md rounded-bl-md text-center"
                        : " text-gray-900 py-1 text-md font-semibold rounded-bl-md text-center cursor-pointer"
                    }
                    onClick={() => setActiveAuth(0)}
                  >
                    signup
                  </div>

                  <div
                    className={
                      activeauth === 1
                        ? "bg-gray-900 text-white py-1 text-md font-semibold rounded-tr-md rounded-br-md text-center"
                        : " text-gray-900 py-1 text-md font-semibold rounded-tr-md rounded-br-md text-center cursor-pointer"
                    }
                    onClick={() => {
                      setActiveAuth(1);
                    }}
                  >
                    login
                  </div>
                </div>


                <div className='text-yellow-600 text-[14px] font-semibold ' >
                  {msgm && msgm.length > 1 && (<><CampaignOutlined/> {msgm}</>)}
                  </div>

                <div>
                  <div id="signInDiv"></div>
                </div>

                {activeauth === 0 && (
                  <div>
                    <div className="text-[14px]">
                      Your name <span className="text-red-500">*</span>
                    </div>
                    <InputBase
                      sx={{
                        border: "1px solid rgb(156,163,175,0.6)",
                        bgcolor: "white",
                        pl: 2,
                        py: "6px",
                        width: "100%",
                        borderRadius: "7px",
                        fontSize: { lg: "15px", xs: "15px" },
                        fontWeight: 500,
                      }}
                      placeholder="e.g daniel Cleaves"
                      id="name"
                    />
                  </div>
                )}

                <div>
                  <div className="text-[14px]">
                    Your email <span className="text-red-500">*</span>
                  </div>
                  <InputBase
                    sx={{
                      border: "1px solid rgb(156,163,175,0.6)",
                      bgcolor: "white",
                      pl: 2,
                      py: "6px",
                      width: "100%",
                      borderRadius: "7px",
                      fontSize: { lg: "15px", xs: "15px" },
                      fontWeight: 500,
                    }}
                    placeholder="e.g austinalf@gmail.com"
                    id="email"
                  />
                </div>

                <div>
                  <div className="text-[14px]">Password</div>
                  <InputBase
                    type="password"
                    sx={{
                      border: "1px solid rgb(156,163,175,0.6)",
                      bgcolor: "white",
                      pl: 2,
                      py: "6px",
                      width: "100%",
                      borderRadius: "7px",
                      fontSize: { lg: "15px", xs: "15px" },
                      fontWeight: 500,
                    }}
                    placeholder="e.g *****"
                    id="password"
                  />
                </div>

                <div
                  className="text-center text-[13px] font-semibold bg-gray-900 text-white py-2"
                  onClick={() => {
                    if (activeauth === 0) {
                      register();
                    }else{
                      login()
                    }
                  }}
                >
                  hey! {activeauth === 0 ? "register" : "login"} first
                </div>
              </div>
            )}
    </div>
  )
}

export default Auth